import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0 p-2 px-3 md:px-4 justify-content-center payable" }
const _hoisted_2 = { class: "col-12 justify-content-center m-0 p-0" }
const _hoisted_3 = { class: "grid p-0 m-0" }
const _hoisted_4 = { class: "col-4 flex p-0 m-0 pr-1 justify-content-end align-items-center" }
const _hoisted_5 = { class: "formgrid grid justify-content-between" }
const _hoisted_6 = { class: "col-8 md:col-9 lg:col-10" }
const _hoisted_7 = { class: "grid p-0 m-0 pt-2" }
const _hoisted_8 = { class: "col-12 p-0 m-0" }
const _hoisted_9 = { class: "text-sm" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "grid p-0 m-0 pt-2" }
const _hoisted_12 = { class: "col-12 p-0 m-0" }
const _hoisted_13 = { class: "col-1 grid pt-1 pl-0 lg:pl-4 custom-date-range-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PayablesTable = _resolveComponent("PayablesTable")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_AddDateRangeDialog = _resolveComponent("AddDateRangeDialog")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, { class: "col-12 font-semibold text-lg p-2 invoice-section" }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "col-8 p-0 m-0 pl-3 pt-2" }, "Invoices Due Next:", -1)),
            _createElementVNode("div", _hoisted_4, [
              false
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: "New Payable",
                    icon: "pi pi-plus",
                    "icon-pos": "left",
                    class: "text-sm font-semibold",
                    onClick: _ctx.handleAddPayable
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_TabView, {
                scrollable: true,
                "active-index": _ctx.activeTab,
                onTabChange: _ctx.handleTabChange,
                class: "mt-1"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateRanges, (range) => {
                    return (_openBlock(), _createBlock(_component_TabPanel, {
                      key: range.label
                    }, {
                      header: _withCtx(() => [
                        _createVNode(_component_Button, {
                          label: `${range.label} Days`,
                          class: "text-sm"
                        }, null, 8, ["label"])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_PayablesTable, {
                              tableName: `Invoices Due Next ${range.label} Days`,
                              range: range,
                              defaultFilters: { status: 'O' },
                              rowsPerPage: 25
                            }, null, 8, ["tableName", "range"])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraDateRanges, (range, index) => {
                    return (_openBlock(), _createBlock(_component_TabPanel, {
                      key: range.label
                    }, {
                      header: _withCtx(() => [
                        _createVNode(_component_Button, { class: "text-sm extra-tab-button" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(range.label), 1),
                            _createElementVNode("i", {
                              class: "pi pi-times",
                              onClick: ($event: any) => (
                          _ctx.handleCloseCustomTab(range.label, index, $event)
                        )
                            }, null, 8, _hoisted_10)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_PayablesTable, {
                              tableName: range.label,
                              range: range,
                              isCustomTab: true,
                              defaultFilters: { status: 'O' },
                              rowsPerPage: 25
                            }, null, 8, ["tableName", "range"])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["active-index", "onTabChange"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_AddDateRangeDialog, { onAddTab: _ctx.addTab }, null, 8, ["onAddTab"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}